<template>
  <main>
    <LazyHydrate when-visible>
      <produkt-section />
    </LazyHydrate>

    <LazyHydrate never>
      <info-section />
    </LazyHydrate>

    <LazyHydrate when-visible>
      <pouziti-section />
    </LazyHydrate>
  </main>
</template>

<script>
export default {
  name: 'PageIndex',
  components: {
    ProduktSection: () => import('~/components/Sections/ProduktSection/ProduktSection'),
    InfoSection: () => import('~/components/Sections/InfoSection/InfoSection'),
    PouzitiSection: () => import('~/components/Sections/PouzitiSection/PouzitiSection')
  },
  head: () => ({
    titleTemplate: '4in1clean.cz - Čistič 4v1'
  })
}
</script>
